import React from 'react';

import {
  H4,
  NamedLink,
  OrderPanel,
  IconCheckmark
} from '../../../components';
import { FormattedMessage, intlShape, useIntl } from '../../../util/reactIntl';
import defaultConfig from '../../../config/configDefault';

import ActionBarMaybe from './ActionBarMaybe';
import SectionTextMaybe from './SectionTextMaybe';
// import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import SectionGallery from './SectionGallery';
import SectionOffersMaybe from './SectionOffersMaybe';
// import CustomListingFields from '../CustomListingFields';

import css from './ProfileListingContent.module.css';

const ProfileListingContent = props => {
  const {
    currentListing,
    listingId,
    listingSlug,
    listingPathParamType,
    listingType,
    listingConfig,

    offerListings,
    fetchOffesrInProgress,
    fetchOffesrError,

    isOwnListing,
    authorDisplayName,
    ensuredAuthor,
    currentUser,
    isAuthenticated,

    intl,

    config,
    params,

    reviews,
    fetchReviewsError,
    sendInquiryError,
    fetchLineItemsError,

    inquiryModalOpen,
    onContactUser,
    onSubmitInquiry,
    setInquiryModalOpen,
    handleOrderSubmit,
    sendInquiryInProgress,

    monthlyTimeSlots,
    onFetchTimeSlots,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,

    listingTab,
    richTitle,

    payoutDetailsWarning,
    noPayoutDetailsSetWithOwnListing,

    onManageDisableScrolling,
  } = props;

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  const listingFields = listingConfig?.listingFields;

  const isOpenForCollaborations = publicData?.collaborationAgree;

  const tagsColors = defaultConfig?.tagsColors || []
  const techniqueTypes = publicData?.techniqueTypes;
  const techniqueTypesOptions = listingFields.find(f => f.key === 'techniques')?.enumOptions;
  const techniqueTypesLabels = techniqueTypesOptions.filter(o => techniqueTypes?.find(t => t.includes(o.option)));

  return (
    <div className={css.contentWrapperForProductLayout}>

      <div className={css.sectionHeroContainer}>
        {currentListing.id && noPayoutDetailsSetWithOwnListing && (
          <ActionBarMaybe
            className={css.actionBarForProductLayout}
            isOwnListing={isOwnListing}
            listing={currentListing}
            showNoPayoutDetailsSet={noPayoutDetailsSetWithOwnListing}
          />
        )}
        {currentListing.id && (
          <ActionBarMaybe
            className={css.actionBarForProductLayout}
            isOwnListing={isOwnListing}
            listing={currentListing}
            editParams={{
              id: listingId.uuid,
              slug: listingSlug,
              type: listingPathParamType,
              tab: listingTab,
              kind: listingType === 'profile-booking' ? 'profile' : 'treatment',
            }}
          />
        )}
        <div className={css.sectionHero}>

          <div className={css.orderColumnForProductLayout}>

            <SectionAuthorMaybe
              title={title}
              listing={currentListing}
              authorDisplayName={authorDisplayName}
              onContactUser={onContactUser}
              isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
              onCloseInquiryModal={() => setInquiryModalOpen(false)}
              sendInquiryError={sendInquiryError}
              sendInquiryInProgress={sendInquiryInProgress}
              onSubmitInquiry={onSubmitInquiry}
              currentUser={currentUser}
              onManageDisableScrolling={onManageDisableScrolling}
            />

            <OrderPanel
              className={css.productOrderPanel}
              listing={currentListing}
              isOwnListing={isOwnListing}
              onSubmit={handleOrderSubmit}
              currentUser={currentUser}
              authorLink={
                <NamedLink
                  className={css.authorNameLink}
                  name="ListingPage"
                  params={params}
                  to={{ hash: '#author' }}
                >
                  {authorDisplayName}
                </NamedLink>
              }
              title={<FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />}
              titleDesktop={
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage id="ListingPage.orderTitle" values={{ title: richTitle }} />
                </H4>
              }
              payoutDetailsWarning={payoutDetailsWarning}
              author={ensuredAuthor}
              onManageDisableScrolling={onManageDisableScrolling}
              onContactUser={onContactUser}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              validListingTypes={config.listing.listingTypes}
              marketplaceCurrency={config.currency}
              dayCountAvailableForBooking={config.stripe.dayCountAvailableForBooking}
              marketplaceName={config.marketplaceName}
              showModalOnDesktop
              isProfilePanel
            />
          </div>

          <SectionGallery
            rootClassName={css.productGalleryHolder}
            listing={currentListing}
            variantPrefix={config.layout.listingImage.variantPrefix}
          />
        </div>
      </div>

      <div className={css.mainColumnForProductLayout}>

        {!!techniqueTypesLabels?.length && (
          <ul className={css.techniqueTypesList}>
            {techniqueTypesLabels.map((t, i) => {

              let colorIndex = i % tagsColors.length;

              return (
                <li
                  key={i}
                  style={{ backgroundColor: tagsColors[colorIndex] }}
                  className={css.techniqueTypesListItem}
                >
                  {t.label}
                </li>
              );
            })}
          </ul>
        )}

        <SectionTextMaybe
          text={description}
          heading={<FormattedMessage id="ListingPage.aboutMeTitle" />}
        />

        {isOpenForCollaborations && (
          <p className={css.collaborationInfo}>
            <IconCheckmark className={css.collaborationIcon} size='bigBorder' />
            <FormattedMessage id="ListingPage.openForCollaborations" />
          </p>
        )}

        {/* <CustomListingFields
          publicData={publicData}
          metadata={metadata}
          listingFieldConfigs={listingConfig.listingFields}
          categoryConfiguration={config.categoryConfiguration}
          intl={intl}
        /> */}

        <SectionOffersMaybe
          offerListings={offerListings}
          fetchOffesrInProgress={fetchOffesrInProgress}
          fetchOffesrError={fetchOffesrError}
          listingFields={listingFields}
        />

        <SectionMapMaybe
          geolocation={geolocation}
          publicData={publicData}
          listingId={currentListing.id}
          mapsConfig={config.maps}
        />
        {/* <SectionReviews reviews={reviews} fetchReviewsError={fetchReviewsError} /> */}
      </div>
    </div>
  );
};

export default ProfileListingContent;
