import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { RemoveImageButton } from '../FieldAzureImageUpload/FieldAzureImageUpload';
import { IconFile, IconPDF } from '../../components';

import css from './AzureImageDisplay.module.css';

const AzureImageDisplay = (props) => {
  const { value, handleDelete, className, isRounded, isFileFormat } = props;

  const isDev = typeof window !== 'undefined' && window.location?.hostname === 'localhost';
  const [imageUrl, setImageUrl] = useState();

  if (!value) return null;

  useEffect(() => {
    axios
      .get(`${isDev ? 'http://localhost:3500' : ''}/api/azure-stream?fileName=` + value)
      .then(resp => {
        setImageUrl(resp.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, [value]);

  const handleDeleteFile = e => {
    e.stopPropagation();
    e.preventDefault();
    return axios.get(
      `${isDev ? 'http://localhost:3500' : ''}/api/azure-delete?fileName=` + value,
      {
        responseType: 'blob',
      }
    ).then(resp => {
      handleDelete(value);

    }).catch(error => {
      console.log(error);
      handleDelete(value);
    });
  };

  const classes = classNames(className || isRounded ? css.imageRounded : css.image, { [css.file]: isFileFormat });

  const trimFilename = (filename) => {
    const index = filename.indexOf('-');
    return index !== -1 ? filename.substring(index + 1) : filename;
  };
  const fileName = trimFilename(value)
  const isImage = /\.(jpg|jpeg|png|gif|bmp|webp)$/i.test(fileName);

  if (isFileFormat) {
    return (
      <div>

        <span className={classes} onClick={() => window.open(imageUrl)}>
          <IconFile />
          {fileName}
        </span>
        {handleDelete && <RemoveImageButton onClick={handleDeleteFile} />}
      </div>
    )
  } else {
    return (
      <div>
        {isImage ? (
          <img className={classes} src={imageUrl} alt="image file"
            onClick={() => window.open(imageUrl)} />
        ) : (
          <div className={classNames(classes, css.filePreview)} onClick={() => window.open(imageUrl)}>
            <IconPDF />
          </div>
        )}

        {handleDelete && <RemoveImageButton onClick={handleDeleteFile} />}
      </div>
    )
  }
}

export default React.memo(AzureImageDisplay);