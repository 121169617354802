import React, { useState } from 'react';
import AliceCarousel from 'react-alice-carousel';
import classNames from 'classnames';
import { transformToEmbedUrl } from '../../../util/transformToEmbedUrl';

import AzureImageDisplay from '../../../components/AzureImageDisplay/AzureImageDisplay';
import { IconCarouselArrow } from '../../../components';

import css from './ProfileListingContent.module.css';

const SectionGallery = props => {
  const { listing, variantPrefix, rootClassName } = props;
  const images = listing.images;
  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];

  const {
    publicData = {},
  } = listing?.attributes;

  const videoUrl = publicData?.videoUrl;
  const uploadedImages = publicData?.uploadedImages;

  const videoItem = videoUrl && (
    <div className={classNames(css.productGalleryItem, css.productGalleryVideo)}>

      <iframe
        style={{ borderRadius: "var(--borderRadiusFull)" }}
        width="100%"
        height={425}
        src={transformToEmbedUrl(videoUrl)}
        title="video"
        frameBorder={0}
        allowFullScreen
      />
    </div>
  );

  const photoItems = !!uploadedImages?.length ? uploadedImages.map(image => {
    return (
      <div className={classNames(css.productGalleryItem, css.productGalleryImage)}>
        <AzureImageDisplay
          key={image}
          value={image}
        />
      </div>
    )
  }): [];

  const items = [videoItem, ...photoItems]

  const [currentIndex, setCurrentIndex] = useState(0);
  const totalItems = items?.length;

  const prevButton = () => (
    <button
      onClick={() => slidePrev()}
      disabled={currentIndex === 0}
    >
      <IconCarouselArrow direction="left" disabled={currentIndex === 0} />
    </button>
  );

  const nextButton = () => (
    <button
      onClick={() => slideNext()}
      disabled={currentIndex === totalItems - 1}
    >
      <IconCarouselArrow direction="right" disabled={currentIndex === totalItems - 1} />
    </button>
  );

  const slidePrev = () => {
    setCurrentIndex((prev) => (prev > 0 ? prev - 1 : prev));
  };

  const slideNext = () => {
    setCurrentIndex((prev) => (prev < totalItems - 1 ? prev + 1 : prev));
  };

  const handleSlideChange = ({ item }) => {
    setCurrentIndex(item);
  };

  if(!items?.filter(i => !!i)?.length) return null;

  return (
    <section className={classNames(css.productGallery, rootClassName)} data-testid="carousel">

      <AliceCarousel
        items={items.filter(i => !!i)}
        infinite={false}
        activeIndex={currentIndex}
        onSlideChanged={handleSlideChange}
        renderPrevButton={prevButton}
        renderNextButton={nextButton}
        disableDotsControls
        disableButtonsControls={items.filter(i => !!i)?.length <= 1}
      />
    </section> 
  );
};

export default SectionGallery;
