import React from 'react';
import ListingImageGallery from '../ListingImageGallery/ListingImageGallery';
import { LINE_ITEM_ITEM } from '../../../util/types'

import css from './TreatmentListingContent.module.css';
import classNames from 'classnames';

const addVariantAndSortImages = (images, imagesWithVariants, productVariantLineItem) => {
  return images.map(el => {
    if (!!el?.id?.uuid) {
      const id = el.id.uuid;
      const productVariant = imagesWithVariants.find(i => i.id === id)?.productVariant;
      el.productVariant = productVariant;
      return el;
    }
    return null;
  }).filter(i => i.productVariant === productVariantLineItem || i.productVariant === 'all')

  /*
  The option when photos of the product variant are placed at the beginning

  const variantAll = images.filter(i => i.productVariant === 'all')
  const variantLineItem = images.filter(i => i.productVariant === productVariantLineItem)
  sortedImages = [...variantLineItem, ...variantAll]
  */
}

const SectionGallery = props => {
  const { listing, variantPrefix, lineItems } = props;    
  const productVariantLineItem = !!lineItems && lineItems.find(item => item.code === LINE_ITEM_ITEM)?.productVariant
  
  const images = listing.images;
  const imagesWithVariants = listing?.attributes?.publicData?.imagesWithVariants

  const hasImageVariants = !!imagesWithVariants && imagesWithVariants.length > 0

  let sortedImages =  []
  if (hasImageVariants) {
    sortedImages = addVariantAndSortImages(images, imagesWithVariants, productVariantLineItem)
  }

  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];
  return (
    <div className={classNames(css.productGallery, css.productGalleryMain)} data-testid="carousel">
      <ListingImageGallery
        images={hasImageVariants ? sortedImages : images}
        imageVariants={imageVariants}
        thumbnailVariants={thumbnailVariants}
        customPadding='622'
      />
    </div>
  );
};

export default SectionGallery;
