import React, { useState } from 'react';
import classNames from 'classnames';

import { bool, func, oneOfType, string } from 'prop-types';
import { FormattedMessage } from '../../../util/reactIntl';
import { ensureUser, ensureCurrentUser } from '../../../util/data';
import { propTypes } from '../../../util/types';
import {
  AvatarLarge,
  NamedLink,
  InlineTextButton,
  IconCircleArrow,
  ComingSoonBadge,
  Modal,
  Button
} from '../../../components';
import { createSlug } from '../../../util/urlHelpers';

import CertificatesPopup from '../CertificatesPopup/CertificatesPopup';

import css from './UserCardForTreatment.module.css';

// Approximated collapsed size so that there are ~three lines of text
// in the desktop layout in the author section of the ListingPage.

const UserCardForTreatment = props => {
  const {
    rootClassName,
    className,
    user,
    currentUser,
    onContactUser,
    showContact,
    title,
    listing,
    onManageDisableScrolling
  } = props;

  const userIsCurrentUser = user && user.type === 'currentUser';
  const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const isCurrentUser =
    ensuredUser.id && ensuredCurrentUser.id && ensuredUser.id.uuid === ensuredCurrentUser.id.uuid;
  const { displayName, bio } = ensuredUser.attributes.profile;

  const handleContactUserClick = () => {
    onContactUser(user);
  };

  const hasBio = !!bio;
  const classes = classNames(rootClassName || css.root, className);
  const linkClasses = classNames(css.links, {
    [css.withBioMissingAbove]: !hasBio,
  });

  const separator =
    isCurrentUser || !showContact ? null : <span className={css.linkSeparator}>•</span>;

  const contact = showContact ? (
    <InlineTextButton
      rootClassName={css.contact}
      onClick={handleContactUserClick}
      enforcePagePreloadFor="SignupPage"
    >
      <FormattedMessage id="UserCard.contactUser" />
    </InlineTextButton>
  ) : null;

  const editProfile = isCurrentUser ? (
    <NamedLink className={css.editProfile} name="ProfileSettingsPage">
      <FormattedMessage id="ListingPage.editProfileLink" />
    </NamedLink>
  ) : null;

  const links = ensuredUser.id ? (
    <p className={linkClasses}>
      <NamedLink className={css.link} name="ProfilePage" params={{ id: ensuredUser.id.uuid }}>
        <FormattedMessage id="UserCard.viewProfileLink" />
      </NamedLink>
      {separator}
      {isCurrentUser ? editProfile : contact}
    </p>
  ) : null;

  const {
    publicData = {},
  } = listing.attributes;

  const languages = publicData?.languages;
  const address = publicData?.location?.address;
  const certificatesUploaded = publicData?.certificatesUploaded;

  const [modalOpen, setModalOpen] = useState(false)

  return (
    <div className={classes}>
      <div className={css.content}>
        <AvatarLarge className={css.avatar} user={user} disableProfileLink />
        {/* {links} */}
        <div className={css.info}>
          <div className={css.headingRow}>

            <NamedLink
              className={css.headingLink}
              name="ListingPage"
              params={{ id: listing?.id?.uuid, slug: createSlug(title) }}
            >
              <FormattedMessage id="UserCard.profileHeading" values={{ name: title }} />
              <IconCircleArrow />
            </NamedLink>

          </div>

          {!!languages?.length && (
            <p className={css.languages}>
              <b>
                <FormattedMessage id="UserCard.languages" />
              </b>
              <span className={css.languagesItems}>
                {languages.join(', ')}
              </span>
            </p>
          )}

          {!!address && (
            <p className={css.address}>
              {address}
            </p>
          )}

          <CertificatesPopup
            certificates={certificatesUploaded}
            onManageDisableScrolling={onManageDisableScrolling}
          />
        </div>
      </div>

      <Button
        className={css.bookBtn}
        onClick={() => setModalOpen(true)}
      >
        <FormattedMessage id="OrderPanel.ctaButtonMessageBooking" />
      </Button>

      <Modal
        id="ListingPage.comingSoon"
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
        closeButtonMessage={' '}
        onCustomClose={() => setModalOpen(false)}
      >
        <div>
          <ComingSoonBadge />
        </div>
      </Modal>
    </div>
  );
};

UserCardForTreatment.defaultProps = {
  rootClassName: null,
  className: null,
  user: null,
  currentUser: null,
  showContact: true,
};

UserCardForTreatment.propTypes = {
  rootClassName: string,
  className: string,
  user: oneOfType([propTypes.user, propTypes.currentUser]),
  currentUser: propTypes.currentUser,
  onContactUser: func.isRequired,
  showContact: bool,
};

export default UserCardForTreatment;
