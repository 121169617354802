import React from 'react';
import { FormattedMessage } from '../../../util/reactIntl';
import { Heading, OfferCard, IconSpinner } from '../../../components';

import css from './ProfileListingContent.module.css';

const SectionOffersMaybe = props => {

  const {
    offerListings,
    fetchOffesrInProgress,
    fetchOffesrError,
    listingFields
  } = props;

  const cardRenderSizes = () => {
    // Panel width relative to the viewport
    const panelMediumWidth = 50;
    const panelLargeWidth = 100;
    return [
      '(max-width: 767px) 100vw',
      `(max-width: 1023px) ${panelMediumWidth}vw`,
      `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
      `${panelLargeWidth / 3}vw`,
    ].join(', ');
  };

  if(!offerListings?.length) return null;

  return (
    <section className={css.sectionOffers} data-testid="offers">

      <Heading as="h2" rootClassName={css.sectionHeading}>
        <FormattedMessage id="ListingPage.myOffersTitle" />
      </Heading>

      {fetchOffesrInProgress ? (
        <div className={css.offerCardsSpinner}>
          <IconSpinner />
        </div>
      ) : (
        <div className={css.offerCards}>
          {offerListings.map(l => (
            <OfferCard
              className={css.offerCard}
              key={l.id.uuid}
              listing={l}
              renderSizes={cardRenderSizes()}
              setActiveListing={() => { }}
              listingFields={listingFields}
            />
          ))}
        </div>
      )}
    </section>
  );
};

export default SectionOffersMaybe;
